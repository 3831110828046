import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';


const showimgdiv = {
  maxWidth:"900px",
  margin: "0 auto",
    paddingTop:" 25px"

}

const btnAlignment = {
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  marginTop:"15px"
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImgPreview(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

 

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog fullScreen open={props.Open} onClose={props.handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            {/* <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton> */}
            <Typography variant="h6" className={classes.title}>
              Preview Image
            </Typography>
          
          </Toolbar>
        </AppBar>
       <div style={showimgdiv}> 
         <div >
           {props.Preview !== "" ? 
           <img src={props.Preview} alt="" style={{width:"100%" , height:"100%"}} /> : null
           }
         </div>
        <div style={btnAlignment} className="my-3"> 
        <Button variant="contained"   color="primary" onClick={props.handleClose} >Neu</Button>
        <Button variant="contained" onClick={props.handleUsePhote}  imgsrc={props.Preview} color="primary"  >Foto benutzen</Button>
        </div>
       </div>
      </Dialog>
    </div>
  );
}

import React, { useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { AnamnesisFlow } from './types/AnamnesisFlow'
import { InvitationDetails } from './types/InvitationDetails'
import { IndividualLinkParams } from './types/Params'
import { DocumentFlow } from './pages/DocumentFlow'
import { DocumentFlowContext } from './contexts/DocumentFlowContext'
import { PracticeContext } from './contexts/PracticeContext'
import { templateServer, submissionServer } from './config'
import { ThankYou } from './pages/ThankYou'
import { useParams } from 'react-router-dom'
import { Welcome } from './pages/Welcome'
import { Fallback } from './pages/Fallback'
import { Loading } from './pages/Loading'

import { getLanguagesSubset } from './utils/languageHelpers'
import { LanguageContext } from './contexts/LanguageContext'

const IndividualLink = () => {
  const { invitationToken } = useParams<IndividualLinkParams>()
  const { setPractice, loaded } = useContext(PracticeContext)
  const { stage, setTemplates, setIsLoading } = useContext(DocumentFlowContext)
  const { setSupportedLanguages } = useContext(LanguageContext)

  const [
    { loading: loadingFlow, error: flowError },
    requestFlowData,
  ] = useAxios<AnamnesisFlow>(
    {},
    {
      manual: true,
    },
  )

  const [
    { loading: loadingInvitationDetails, error: invitationDetailsError },
    requestInvitationDetails,
  ] = useAxios<InvitationDetails>(`${submissionServer}/${invitationToken}`, {
    manual: true,
  })

  useEffect(() => {

    setIsLoading(true)
    requestInvitationDetails()
      .then((invitationDetailsResult) => {
        const flowToken = invitationDetailsResult.data.token
        setTemplates(invitationDetailsResult.data.document_templates)
        return requestFlowData({ url: `${templateServer}/${flowToken}` })
      })
      .then((result) => {
        setPractice(result.data.practice)
        setSupportedLanguages(
          getLanguagesSubset(result.data.document_templates),
        )
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
      })
  }, [
    requestFlowData,
    requestInvitationDetails,
    setPractice,
    setTemplates,
    setIsLoading,
    setSupportedLanguages,
  ])

  if (loadingFlow || loadingInvitationDetails) return <Loading />
  if (flowError || invitationDetailsError) return <Fallback error />

  if (loaded) {
    switch (stage) {
      case 'welcome':
        return <Welcome />
      case 'documentFlow':
        return (
          <DocumentFlow
            extraSubmissionData={{ invitation_token: invitationToken }}
          />
        )
      case 'thankYou':
        return <ThankYou />
    }
  } else {
    return <Loading />
  }
  return null;
}

export default IndividualLink

import React, { useState } from 'react'
import { LanguageContext } from './LanguageContext'
import { IntlProvider } from 'react-intl'
import { getLanguage } from '../translations/languageConfig'

export const LanguageProvider = (props: any) => {
  const setLocale = (locale: string) => {
    updateLanguage((prevState) => {
      const newState = { ...prevState }
      return Object.assign(newState, {
        locale,
      })
    })
  }
  const setSupportedLanguages = (supportedLanguages: string[]) => {
    updateLanguage((prevState) => {
      const newState = { ...prevState }
      const browserLocale = navigator.language.split(/[-_]/)[0]
      return Object.assign(newState, {
        supportedLanguages: supportedLanguages.map((l) => getLanguage(l)),
        locale: supportedLanguages.includes(browserLocale)
          ? browserLocale
          : supportedLanguages[0],
      })
    })
  }

  const languageState = {
    locale: 'de',
    supportedLanguages: [],
    setLocale,
    setSupportedLanguages,
  }

  const [language, updateLanguage] = useState(languageState)

  const msg = getLanguage(language.locale).messages

  return (
    <LanguageContext.Provider value={language}>
      <IntlProvider locale={language.locale} messages={msg}>
        {props.children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

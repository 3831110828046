import { DocumentFlowStage } from './types/DocumentFlow'

// New Local Url's
// export const templateServer = 'https://localhost:44310/api/v1/anamnesis_at_home_flows'
// export const ImageServer = 'https://localhost:44310/'
// export const submissionServer = 'https://localhost:44310/api/public/v1/anamnesis_flow_submissions'


// New Live Url's
export const templateServer ='https://api.altona.app/api/v1/anamnesis_at_home_flows' 
export const ImageServer ='https://api.altona.app/'  
export const submissionServer ='https://api.altona.app/api/public/v1/anamnesis_flow_submissions'



export const athenaUrl = 'https://dampsoft.de/athena'
export const privacyPolicyUrl = 'https://www.dampsoft.de/datenschutz/'
export const imprintUrl = 'https://www.dampsoft.de/impressum/'

/*
 / ########################################
 / ####### DEV MODE - Testing Only ########
 / ########################################
*/

export const devMode = false

export const devStage = 'documentFlow' as DocumentFlowStage
export const devStepIndex = 0
export const fillAnamneseTemplate = true
export const fillDatenschutzTemplate = true
export const fillTestTemplate = true
export const fillConsents = true

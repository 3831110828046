import React, { useState } from 'react'
import { Practice } from '../types/Practice'
import { PracticeContext } from './PracticeContext'

export const PracticeProvider = (props: any) => {
  const setPractice = (practice: Practice) => {
    updatePractice((prevState) => {
      const newState = { ...prevState }
      return Object.assign(newState, {
        ...practice,
        loaded: true,
      })
    })
  }

  const practiceState = {
    name: '',
    address1: '',
    address2: '',
    zip_code: '',
    city: '',
    email: '',
    phone: '',
    logo_url: '',
    website: '',
    dgsvo_data_protection_officer_address: '',
    dgsvo_data_protection_officer_contact: '',
    dgsvo_data_protection_officer_name: '',
    dgsvo_factoring_provide: '',
    loaded: false,
    setPractice,
  }

  const [practiceValue, updatePractice] = useState(practiceState)

  return (
    <PracticeContext.Provider value={practiceValue}>
      {props.children}
    </PracticeContext.Provider>
  )
}

import React ,{useEffect } from 'react';
import { render } from 'react-dom';
import { Stage, Layer, Line, Text,Image } from 'react-konva';
import useImage from 'use-image';


const autoheight = {
  height: "80vh",
  display: "flex",
  alignItems: "center"
}


// the first very simple and recommended way:
const Myimg = (props) => {
  
    const [image] = useImage(props.imghere !=="" ? props.imghere:'./demoimg.jpg');
    return <Image height={props.height} width={props.width} image={image} />;
  };
  

export default function Konvacanvas (props) {
//   const [tool, setTool] = React.useState(props.tool);
  const [lines, setLines] = React.useState([]);
  const isDrawing = React.useRef(false);
  const [imgurl, setimgurl] = React.useState("./demoimg.jpg");
  const [angle, setangle] = React.useState(0);



  const handleMouseDown = (e) => {
     
  
      if(props.isDrawingMode === true){
        isDrawing.current = true;
        const pos = e.target.getStage().getRelativePointerPosition();
        const stroke = props.linecolor;
        const lineHeight = props.lineHeight;
        const tool = props.tool;
        const width = props.imgwidth;
        setLines([...lines, { tool, points: [pos.x, pos.y],stroke,lineHeight,width }]);
      }
    
  };

  const handleMouseMove = (e) => {
    if(props.isDrawingMode === true){

    
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    if (e.target === e.target.getStage()) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getRelativePointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
}
  };

  const handleMouseUp = (e) => {
  
    if(props.isDrawingMode === true){

        isDrawing.current = false;
      }
  };


  return (
    <div className="getcanvas" style={autoheight}>
      <Stage
        width={props.imgwidth}
        height={props.imgheight}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        onTouchMove={handleMouseMove}
        onMouseup={handleMouseUp}
        rotation={props.angle}
        offsetX={props.offx}
        offsetY={props.offy}
        ref={props.export}
        scale={{x:props.scalex,y: props.scaley}}
      >
          <Layer>
          <Myimg height={props.imgheight} width={props.imgwidth} imghere={props.imghere} />
          </Layer>
        <Layer
         
        >
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke={line.stroke}
              strokeWidth={line.lineHeight}
              tension={0.5}
              lineCap="round"
              globalCompositeOperation={
                line.tool === 'eraser' ? 'destination-out' : 'source-over'
              }
            />
          ))}
           
        </Layer>
      </Stage>
 
    </div>
  );
};


import { PatientInterface } from '../types/Patient'
import { FieldMapping, resolveFieldMapping } from '../utils/fieldMappingHelpers'

export const getPatient = (values: any) => {
  return (values as any).patient as PatientInterface
}

export const patientFieldMap: FieldMapping[] = [
  { atnKey: 'patient_anrede', formKeys: ['salutation'] },
  { atnKey: 'patient_name', formKeys: ['first_name', 'last_name'] },
  { atnKey: 'patient_vorname', formKeys: ['first_name'] },
  { atnKey: 'patient_nachname', formKeys: ['last_name'] },
  { atnKey: 'patient_geburtsdatum', formKeys: ['date_of_birth'] },
  {
    atnKey: 'patient_anschrift',
    formKeys: ['address1', 'address2', 'zipcode', 'city'],
  },
  { atnKey: 'patient_adresse_1', formKeys: ['address1'] },
  { atnKey: 'patient_adresse_2', formKeys: ['address2'] },
  { atnKey: 'patient_telefon_privat', formKeys: ['home_phone'] },
  { atnKey: 'patient_telefon_arbeit', formKeys: ['work_phone'] },
  { atnKey: 'patient_telefon_mobil', formKeys: ['cellular_phone'] },
  { atnKey: 'patient_titel', formKeys: ['title'] },
  { atnKey: 'patient_email', formKeys: ['email'] },

  // Add all remaining patient info
  // country?: string
  // fax?: string
  // employer?: string
  // profession?: string
  // insured_salutation?: string
  // insured_title?: string
  // insured_first_name?: string
  // insured_last_name?: string
  // insured_date_of_birth?: string
  // insured_address1?: string
  // insured_address2?: string
  // insured_country?: string
  // insured_phone?: string

  // Missing mappings
  // '{{patient_behandelnder_arzt}}': currentPatient.doctor,
  // '{{patient_telefon_2}}': currentPatient.work_phone,
  // '{{patient_krankenkassennummer}}': currentPatient.kknr,
  // {{patient_geschlecht}}: gender?
]

export const replacePatientContent = (
  content: string,
  patient: PatientInterface,
  activeLang: string,
) => {
  for (let mapping of patientFieldMap) {
    if (mapping.atnKey === 'patient_geburtsdatum') {
      const dob = new Date(patient.date_of_birth).toLocaleDateString(activeLang)
      content = content.replace(`{{${mapping.atnKey}}}`, dob)
    } else {
      content = content.replace(
        `{{${mapping.atnKey}}}`,
        resolveFieldMapping(patient, mapping.formKeys),
      )
    }
  }
  return content
}

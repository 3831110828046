import { createContext } from 'react'

export const CameraContext = createContext({
    showcam: false,
    source: "",
    signpadsPath: "",
    padId: "",
    setImagesourceEvent: (source: string) => {},
    setSignpadEvent: (padId:string,source: string) => {},
    dispatchCameraEvent: (showcam: boolean) => {}
  });
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SignPad from "./SignaturePad"
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Grid from '@material-ui/core/Grid';

const headstyle = {
  background: "#015270",
  color: "#fff",
  padding: "12px 8px"
}
export default function SignPadPopUp(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <IconButton color="default" aria-label="upload picture" onClick={handleClickOpen} component="span">
       <CreateIcon />
            </IconButton> */}

      <Dialog
        open={props.open}
        onClose={props.close}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={headstyle} id="alert-dialog-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>          {"Unterschreiben"}
            </Grid>
            <Grid item style={{ display: "flex" }}>
              <HighlightOffIcon onClick={props.close} />

            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: "8px" }}>
          <SignPad baseString={props.baseString} hideSignpad={props.close} />
        </DialogContent>

      </Dialog>
    </div>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import GestureIcon from '@material-ui/icons/Gesture';
import DrawOnImage from "../pages/DrawOnImage";
import CreateIcon from '@material-ui/icons/Create';
import {CameraContext} from '../contexts/CameraContext'
import {ImageServer} from "../config"
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const imgadjust = {
  width:"80%",
  margin:"0 auto",
  height:"400px",
  textAlign: "center"

}

export default function InitialImagePopUp() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openpopup2, setOpenpopup2] = React.useState(false);
  const { source } =React.useContext(CameraContext);
  const [imgurl, setimgurl] = React.useState(`${ImageServer}${source}`);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenpopup2(false)
  };
  const OpenPopUp2 = () => {
    setOpenpopup2(true);
    console.log(openpopup2)
  };
  const ClosePopUp2 = () => {
    setOpenpopup2(false);
    console.log(openpopup2)
  };
  const getImgBase = (base) => {
    setimgurl(base)
    console.log("check: "+  base)
  }


  return (
    <div>
       <IconButton color="default" aria-label="gesture" onClick={handleClickOpen} component="span">
            <GestureIcon />
       </IconButton>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Initial Image
            </Typography>
          
          </Toolbar>
        </AppBar>
        <div style={imgadjust}>
        <img src={ImageServer+source} alt=""  style={{width:"100%",height:"100%"}} />
        <div style={{textAlign:"center",marginTop:"20px"}}>
          <Button
          variant="contained"
          color="default"
          startIcon={<CreateIcon style={{color:"#015270"}} />}
          style={{color:"#015270"}}
          onClick={OpenPopUp2}
        >
        Zeichnen
      </Button>
        </div>

        </div>
       
      </Dialog>
      {openpopup2 && <DrawOnImage getimg={getImgBase} setBaseUrl={imgurl} ClosepopUp2={ClosePopUp2} OpenPopUp2={openpopup2} />}
    </div>
  );
}

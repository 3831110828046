import React,{useEffect , useRef,useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Slide from '@material-ui/core/Slide';

import Grid from '@material-ui/core/Grid';
import SignPadPopUp from "../pages/signaturePadPopup";
import {CameraContext} from '../contexts/CameraContext'
import axios from 'axios'
import { ImageServer } from '../config'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));



const Sign = {
  width: "250px",
  margin: "35px auto",
  paddingBottom: "10px",
}
const SignField = {
  cursor: "pointer",
  border: "4px dashed #999",
  padding: "20px",
  textAlign: "left",
  height: "200px",
  textAlign: "center"
}
const SignatureCaption = {
  fontStyle: "italic",
  fontSize: "18px",
  margin: "10px 20px",
}
const SignTitle = {
  fontSize: "16px",
  marginBottom: "10px",
}
const SignInfoLabel ={
  marginTop: "10px",
  fonSize: "16px",
  color: "#666",
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SavedSignature(props) {
debugger
  const { setSignpadEvent , signpadsPath } = useContext(CameraContext)
  const classes = useStyles();
  const [imgurl, setimgurl] = React.useState("/graph.jpg");
  const [open, setOpen] = React.useState(false);
  const [opensignpad, setopensignpad] = React.useState(false);
  const [currenttabid, setcurrenttabid] = React.useState(false);
  const [base1, setbase1] = React.useState("");
  const [base2, setbase2] = React.useState("");
  const [base3, setbase3] = React.useState("");
  const img1 = useRef(null);
  const img2 = useRef(null);
  const img3 = useRef(null);
  



  const OpenSignPad = (e) => {
    setopensignpad(true);
    setcurrenttabid(e.target.id)

  };
  const CloseSignPad = () => {
    setopensignpad(false);
  };
  const getbaseString = (base) => { 

    const sign={
      "Logo":base,
    };
    axios({
      method: 'Post',
      url: ImageServer+'atn_editor_api/v1/document_templates/0/medias',
      data:sign
    }).then(response => {
      debugger
      switch (currenttabid) {
        case "tab1":
          setbase1(base);
          setSignpadEvent(props.data.id,response.data)
          break;
        // case "tab2":
        //   setbase2(base);
        //   setSignpadEvent("1",response.data)
        //   break;
        // case "tab3":
        //   setbase3(base);
        //   setSignpadEvent("2",response.data)
  
        //   break;
      
        default:
          break;
      }
    });

    switch (currenttabid) {
      case "tab1":
        setbase1(base);
        // setSignpadEvent("sign1",base)
        break;
      // case "tab2":
      //   setbase2(base);
        // setSignpadEvent("sign2",base)

      //   break;
      // case "tab3":
      //   setbase3(base);
        // setSignpadEvent("sign3",base)

        // break;
    
      default:
        break;
    }

  }
  return (
    <div>
       
     
        <Grid container>
        <Grid item xs={4}>
        <div style={Sign}>
        <div style={SignField} id={props.data.id} onClick={OpenSignPad}>

      {base1 !== "" ? <img src={base1} ref={img1} id={props.data.id} alt="" style={{width:"100%",height: "80%"}} />: <img src="/pointer.svg" ref={img1} id="tab1" alt="" style={{width:"80px",height: "70%"}} /> } 
      <p style={SignatureCaption}>{props.data.errorMessage[props.language]}</p> 
        </div>
       
        <p style={{textAlign:props.data.style.align}}>{props.data.caption[props.language]}</p> 
      </div> 
        </Grid>
        {/* <Grid item xs={4}>
        <div style={Sign}>
        <div style={SignField} id="tab2" onClick={OpenSignPad}>

       {base2 !== "" ? <img src={base2} ref={img2} id="tab2" alt="" style={{width:"100%",height: "80%"}} />: <img src="/pointer.svg" ref={img2} id="tab2" alt="" style={{width:"80px",height: "70%"}} />} 
       <p style={SignatureCaption}>{props.data.errorMessage[props.language]}</p> 
        </div>
       
        <p style={{textAlign:props.data.style.align}}>{props.data.caption[props.language]}</p> 
      </div> 
        </Grid>
        <Grid item xs={4}>
        <div style={Sign}>
        <div style={SignField}  id="tab3" onClick={OpenSignPad}>

       {base3 !== "" ?  <img src={base3} ref={img3} id="tab3" alt="" style={{width:"100%",height: "80%"}} />:<img src="/pointer.svg" ref={img3} id="tab3" alt="" style={{width:"80px",height: "70%"}} />} 
       <p style={SignatureCaption}>SignatureCaption</p> 
        </div>
       
        <p style={SignTitle}>Unterschrift OPeter Beispiel/Bevollmachtigter (*)</p> 
      </div> 
        </Grid> */}
        </Grid>
     
        <SignPadPopUp baseString={getbaseString} open={opensignpad} close={CloseSignPad}/>

    </div>
  );
}

import React, {
    useEffect,
    useRef,
    Fragment,
    useCallback,
    useState
  } from 'react'
  import { render } from 'react-dom'
  // @ts-ignore  
  import { useSvgDrawing } from 'react-hooks-svgdrawing'
  import styled from 'styled-components'
// @ts-ignore  
  import Card from '@material-ui/core/Card';
  // @ts-ignore  

import CardActions from '@material-ui/core/CardActions';
// @ts-ignore  

import CardContent from '@material-ui/core/CardContent';
import { theme } from '../utils/theme'
// @ts-ignore  

import { Button } from '@material-ui/core'


  //@ts-ignore
  import Pressure from 'pressure'
  // TODO: Add e2e tests for library file.
  // import { useSvgDrawing } from '../../lib/index.esm'
  
const Wrapper = styled.div`
padding-top: 50px;

${theme.breakpoints.down('sm')} {
  padding-top: 10px;
}
`
 const buttonmargin = {
     margin: "2px"

 }
 const fieldsetSetting = {
   display: "flex",
   alignItems : "center",
   justifyContent : "space-between"
 }
  const getRandomInt = (max: number): number =>
    Math.floor(Math.random() * Math.floor(max))
  const getRandomColor = (): string =>
    `#${Array.from({ length: 3 }, () =>
      String(getRandomInt(255).toString(16)).padStart(2, '0')
    ).join('')}`
  
  const DrawIntoImage = () => {
    const [
      divRef,
      {
        instance,
        changePenColor,
        changePenWidth,
        getSvgXML,
        download,
        undo,
        clear
      }
    ] = useSvgDrawing({
      penWidth: 3,
      penColor: '#000'
    })
    const [xml, setXml] = useState('')
    const [penMode, setPenMode] = useState<string>('normal')
    const [penWidth, setPenWidth] = useState<number>(5)
    const [penThinnerWidth, setPenThinnerWidth] = useState<number>(0)
    const handleColor = useCallback(() => {
      changePenColor(getRandomColor())
    }, [changePenColor])
  
    const handlePenWidth = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setPenWidth(Number(e.target.value))
        changePenWidth(Number(e.target.value))
      },
      [changePenWidth]
    )
    const handleChangeXML = useCallback(() => {
      setXml(getSvgXML() || '')
    }, [getSvgXML])
    const handleChangeMode = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setPenMode(e.target.value)
      },
      []
    )
    const handleClickDownload = useCallback(
      (ext: 'svg' | 'png' | 'jpg') => () => {
        download(ext)
      },
      [download]
    )
    const pressureChange = useCallback(
      (force: any, event: any) => {
        setPenThinnerWidth(30 - Math.floor(force * 40))
      },
      [setPenThinnerWidth]
    )
  
    useEffect(() => {
      if (penMode === 'normal') return
  
      const stopId = setInterval(() => {
        if (penMode === 'rainbow') {
          changePenColor(getRandomColor())
        }
        if (penMode === 'random') {
          changePenWidth(getRandomInt(50) + 5)
        }
        if (penMode == 'thinner') {
          changePenWidth(penThinnerWidth)
        }
      }, (instance && instance.delay) || 20)
      return () => clearInterval(stopId)
    }, [penMode, changePenWidth, changePenColor, instance, penThinnerWidth])
  
    // Pressure -> https://github.com/stuyam/pressure
    useEffect(() => {
      if (!divRef.current) return
      Pressure.set(divRef.current, {
        change: pressureChange
      })
    }, [divRef, pressureChange])
    return (
        <Wrapper>
      <Fragment>
          <Card>
              <CardContent>
        <fieldset style={fieldsetSetting}>
          <label>
            <input
              type="checkbox"
              checked={penMode === 'normal'}
              value="normal"
              onChange={handleChangeMode}
            />
            Normal pen.
          </label>
          <label>
            <input
              type="checkbox"
              checked={penMode === 'thinner'}
              value="thinner"
              onChange={handleChangeMode}
            />
            Pen becoming thinner.
          </label>
          <label>
            <input
              type="checkbox"
              checked={penMode === 'random'}
              value="random"
              onChange={handleChangeMode}
            />
            Pen becoming Random Width.
          </label>
          <label>
            <input
              type="checkbox"
              checked={penMode === 'rainbow'}
              value="rainbow"
              onChange={handleChangeMode}
            />
            Rainbow pen.
          </label>
          {['normal', 'rainbow'].includes(penMode) && (
            <div>
              pen width
              <input
                type="range"
                value={penWidth}
                min={1}
                max={50}
                onChange={handlePenWidth}
              />
            </div>
          )}
          {penMode !== 'rainbow' && (
            <Button style={buttonmargin} variant="contained" color="primary" onClick={handleColor}>Change Color</Button>
          )}
        </fieldset>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flexWrap'
          }}
        >
          <div style={{width:"50%"}}>
            <div
              ref={divRef}
              style={{
                width: 250,
                height: 250,
                border: '1px solid #eee',
                margin: 'auto'
              }}
              onTouchEnd={handleChangeXML}
              onMouseLeave={handleChangeXML}
            />
            
        <div>
       
        </div>
          </div>
          <div
            style={{
              fontSize: '8px',
              
              maxHeight: "252px",
    overflowY: "auto"
            }}
          >
            {xml}
          </div>
        </div>
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:"20px"}}>
        <Button style={buttonmargin} variant="contained" color="primary" onClick={undo}>Undo</Button>
            <Button style={buttonmargin}   variant="contained" color="primary" onClick={clear}>Clear</Button>
            <Button style={buttonmargin}   variant="contained" color="primary" onClick={handleClickDownload('svg')}>Download SVG</Button>
            <Button style={buttonmargin}   variant="contained" color="primary" onClick={handleClickDownload('png')}>Download PNG</Button>
            <Button style={buttonmargin}  variant="contained" color="primary"  onClick={handleClickDownload('jpg')}>Download JPG</Button>
            </div>
        </CardContent>
        </Card>
      </Fragment>
      </Wrapper>
    )
  }
  
export default DrawIntoImage;
import { ATNInputType, RadioButtonType, CheckboxType } from '../types/ATNTypes'
import { devMode } from '../config'
import { DocumentTemplateInterface } from '../types/DocumentFlow'
import { mockInitialConsentValues, mockInitialFormValues } from './mockData'
import { PatientInterface } from '../types/Patient'
import { StepInterface } from '../types/Step'

export const assembleElementFormId = (templateId: string, elementId: string) =>
  `document_templates.${templateId}.${elementId}`

interface FormValues {
  patient: PatientInterface
  document_templates: { [key in string]?: {} }
}

export const assembleSubmission = (
  values: FormValues,
  templates: DocumentTemplateInterface[],
) => {
  const { patient } = values
  let document_templates = {} as { [key in string]?: {} }
  templates.forEach(
    (template, index) =>
      (document_templates[template.id] = values.document_templates[index]),
  )

  return {
    patient,
    document_payloads: document_templates,
  }
}

export const getNestedElement = (nestedObj: any, pathArr: string[]) => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
    nestedObj,
  )
}

export const getElementPath = (elementId: string) => elementId.split('.')

const isSupportedATNInputType = (element: ATNInputType) => {
  return (
    element.required !== undefined &&
    element.type !== 'signature' &&
    element.type !== 'cameraInput'
  )
}

const initializeElement = (element: any, initialValues: any) => {
  if (isSupportedATNInputType(element)) {
    if (element.type === 'selectMultiple') {
      initialValues[element.id] = []
    } else if (isDateInput(element)) {
      initialValues[element.id] = null
    } else {
      initialValues[element.id] = ''
    }
  }
  const optionsOrChildren = element.children || element.options
  if (optionsOrChildren) {
    for (let child of optionsOrChildren) {
      initializeElement(child, initialValues)
    }
  }
}

export const generateInitialFixedStepValues = (step: StepInterface) => {
  let initialValues = {} as { [key in string]?: {} }
  initialValues[step.id] = step.initialValues
  return initialValues
}

export const generateInitialConsentValues = (
  templates: DocumentTemplateInterface[],
) => {
  let initialValues = [] as object[]
  templates.forEach((_) => initialValues.push([]))

  if (devMode) {
    return mockInitialConsentValues(initialValues)
  }

  return { consents: initialValues }
}

export const generateInitialFormValues = (
  templates: DocumentTemplateInterface[],
) => {
  let initialValues = { document_templates: [] as object[] }

  for (let template of templates) {
    let intialTemplateValues = {} as { [key in string]?: {} }
    if (template.atn) {
      for (let element of template.atn) {
        initializeElement(element, intialTemplateValues)
      }
    }
    initialValues.document_templates.push(intialTemplateValues)
  }

  if (devMode) {
    initialValues = mockInitialFormValues(initialValues)
  }

  return initialValues
}

export const getValuesForOptionIds = (
  options: RadioButtonType[] | CheckboxType[],
  selectedOptionIds: string | string[],
) => {
  let optionIds = {} as { [key in string]?: boolean }
  options.forEach((option: RadioButtonType | CheckboxType) => {
    if (selectedOptionIds.includes(option.id)) {
      optionIds[option.id] = true
    } else {
      optionIds[option.id] = false
    }
  })
  return optionIds
}

export const isDateInput = (element: any) =>
  element.dsWinFieldName === 'geburtsdatum' ||
  element.dsWinFieldName === 'geburtsdatum_versicherter'
